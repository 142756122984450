import { Colors } from 'src/assets/styles/colors';
import styled from 'styled-components';

export const BodyType = styled.span<{
  color?: string;
  block?: boolean;
  truncate?: boolean;
}>`
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${props => (props.color ? props.color : Colors.Primary)};
  display: ${props => (props.block ? 'block' : 'inline')};

  ${props =>
    props.truncate
      ? `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
      : ''}
`;
