export const Storage = {
  getItem: <T>(key: string) => {
    const item = localStorage.getItem(key);
    return item !== null ? (JSON.parse(item) as T) : null;
  },

  setItem: <T>(key: string, item: T) => {
    try {
      const serializedState = JSON.stringify(item);
      localStorage.setItem(key, serializedState);
    } catch (error) {
      console.log(error);
    }
  }
};
