import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'src/assets/translations/en.json';
import no from 'src/assets/translations/no.json';
import { Storage } from 'src/utils/storage';
import { Language, LANGUAGE_KEY } from './state/contexts/AuthContext';

export const initializeI18n = () => {
  i18n.use(initReactI18next).init({
    lng: Storage.getItem<string>(LANGUAGE_KEY) ?? Language.English,
    resources: {
      no: { translations: no },
      en: { translations: en }
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      useSuspense: true
    }
  });
};
