import styled from 'styled-components';

export const Header3 = styled.h3`
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: inherit;
  margin: 0;
`;
