import { Sizes } from 'src/assets/styles/sizes';
import { Colors } from 'src/assets/styles/colors';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  height: ${Sizes.HeaderHeight};
  background: #fff;
  z-index: 100;
`;

export const HeaderLeftContainer = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${Sizes.SidebarWidth};
  background: ${Colors.Primary20};
  border-bottom: 1px solid #fff;

  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translateX(0px);

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      transform: translateX(-${Sizes.SidebarWidth});
    `}
`;

export const Logo = styled.img`
  max-width: 160px;
`;

export const HeaderRightContainer = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  align-items: center;
  width: calc(100% - ${Sizes.SidebarWidth});
  box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.16);
  transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      width: 100%;
      flex: 1 0 auto;
    `}
`;

export const Title = styled.h3`
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: ${Colors.Primary};
  padding: 0 1rem;
`;

export const NavButton = styled.button<{
  borderLeft?: boolean;
  borderRight?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 1.25rem;
  border: 0;
  background: none;
  cursor: pointer;

  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid ${Colors.SidebarBackground};
    `}

  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${Colors.SidebarBackground};
    `}
`;

export const Hamburger = styled.div<{ open?: boolean }>`
  position: relative;
  height: 14px;
  width: 20px;
  border: 0;
  background: 0;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: currentColor;
    opacity: 1;
    left: 0;
    transition: transform 0.25s ease-in-out, top 0.25s ease-in-out,
      width 0.25s ease-in-out, left 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: 4px;
  }

  span:nth-child(3) {
    top: 8px;
  }

  span:nth-child(4) {
    top: 12px;
  }

  ${({ open }) =>
    open &&
    css`
      span:nth-child(2) {
        width: 80%;
      }

      span:nth-child(4) {
        width: 60%;
      }
    `}
`;
