import { CircularProgress } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';
import { Colors } from 'src/assets/styles/colors';
import styled, { css } from 'styled-components';
import { Icon } from '../Icon/Icon';

type ButtonType = 'submit' | 'reset' | 'button';

export type ButtonProps = {
  label?: string;
  type?: ButtonType;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
  icon?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PrimaryButton = ({
  label,
  onClick,
  children,
  disabled,
  fullWidth,
  loading,
  className,
  type,
  icon,
  ...rest
}: ButtonProps) => (
  <PrimaryButtonBase
    type={type}
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled}
    $loading={loading}
    className={className ? className : ''}
    {...rest}
  >
    <Fragment>
      {icon && <Icon type={icon} />}
      <span>{children ? children : label}</span>
    </Fragment>
    {loading && (
      <LoadingContainer>
        <CircularProgress style={{ color: Colors.White }} size={24} />
      </LoadingContainer>
    )}
  </PrimaryButtonBase>
);

export const SecondaryButton = ({
  label,
  onClick,
  children,
  disabled,
  loading,
  fullWidth,
  className,
  icon,
  ...rest
}: ButtonProps) => (
  <SecondaryButtonBase
    type="button"
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled}
    $loading={loading}
    className={className ? className : ''}
    {...rest}
  >
    <Fragment>
      {icon && <Icon type={icon} />}
      <span>{children ? children : label}</span>
    </Fragment>
    {loading && (
      <LoadingContainer>
        <CircularProgress style={{ color: Colors.Primary }} size={24} />
      </LoadingContainer>
    )}
  </SecondaryButtonBase>
);

type TertiaryButtonProps = {
  compact?: boolean;
} & ButtonProps;

export const TertiaryButton = ({
  label,
  onClick,
  children,
  disabled,
  fullWidth,
  className,
  icon,
  compact = false,
  ...rest
}: TertiaryButtonProps) => (
  <TertiaryButtonBase
    type="button"
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled}
    className={className ? className : ''}
    compact={compact}
    {...rest}
  >
    <Fragment>
      {icon && <Icon type={icon} />}
      <span>{children ? children : label}</span>
    </Fragment>
  </TertiaryButtonBase>
);

export const ActionButton = ({
  onClick,
  icon,
  disabled,
  className,
  ...rest
}: ButtonProps) => (
  <ActionButtonBase
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={className ? className : ''}
    {...rest}
  >
    <Icon type={icon!} />
  </ActionButtonBase>
);

export const BaseButton = styled.button<{
  fullWidth?: boolean;
  $loading?: boolean;
  compact?: boolean;
}>`
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  height: 2.5rem;
  max-height: 2.5rem;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: 0;
  border-radius: 0.5rem;
  padding: 0.375rem 1rem;
  user-select: none;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  width: ${props => (props.fullWidth ? '100%' : 'auto')};

  i {
    margin-right: 0.5rem;
  }

  &:focus-visible {
    outline: 0;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border-radius: 0.5rem;
      border: 2px solid ${Colors.Primary20};
    }
  }

  &:hover,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const PrimaryButtonBase = styled(BaseButton)`
  background-color: ${Colors.Primary};
  color: #fff;

  &:hover:enabled,
  &:active:enabled {
    background-color: ${Colors.PrimaryDarkest};
  }

  ${props =>
    props.$loading &&
    `
    color: ${Colors.Primary};
  `}
`;

const SecondaryButtonBase = styled(BaseButton)`
  position: relative;
  z-index: 1;
  border: 1px solid ${Colors.Primary};
  color: ${Colors.Primary};
  transition: color 0.15s;
  overflow: hidden;
  background: transparent;

  &:enabled:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 0;
    background: ${Colors.Primary};
    transition: all 0.15s ease-out;
  }

  &:enabled:hover {
    color: #fff;

    &:before {
      height: 100%;
    }
  }

  &:disabled {
    border: 1px solid ${Colors.Primary60};
    color: ${Colors.Primary60};
  }

  ${props =>
    props.$loading &&
    `
    color: #fff;
  `}
`;

const TertiaryButtonBase = styled(BaseButton)`
  border: 1px solid transparent;
  color: ${Colors.Primary};

  ${({ compact }) =>
    compact &&
    css`
      padding: 0.375rem 0;
    `}

  span:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0.25rem;
    height: 1px;
    width: 0;
    left: 50%;
    background: ${Colors.Primary};
    transition: width 0.3s, left 0.3s;
  }

  &:hover:enabled,
  &:active:enabled {
    span:after {
      width: 100%;
      left: 0;
    }
  }
`;

const ActionButtonBase = styled(BaseButton)`
  position: relative;
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  background-color: transparent;
  border-radius: 50%;
  color: ${Colors.Primary};
  padding: 0 !important;

  & i {
    margin-right: 1px;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &:active,
  &:hover {
    background-color: rgba(${Colors.PrimaryRGB}, 0.2);
    outline: none;
    border: none;
  }

  &:disabled,
  &:disabled:hover {
    color: rgba(${Colors.PrimaryRGB}, 0.4);
    background-color: transparent;
  }

  &:focus-visible {
    &:after {
      border-radius: 50%;
    }
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.Primary};
`;
