import {
  css,
  FlattenInterpolation,
  ThemedCssFunction,
  ThemeProps
} from 'styled-components';

export const breakpoints = {
  xsmall: '480px',
  small: '768px',
  medium: '1024px',
  large: '1280px',
  xlarge: '1920px'
};

type BreakpointSize = keyof typeof breakpoints;

export const getMediaBreakpoints = <T extends object>() => {
  const mediaquery = {} as Record<
    keyof typeof breakpoints,
    (
      ...args: Parameters<ThemedCssFunction<T>>
    ) => FlattenInterpolation<ThemeProps<T>>
  >;

  Object.keys(breakpoints).forEach((breakpoint: string) => {
    mediaquery[breakpoint as BreakpointSize] = (
      ...args: Parameters<ThemedCssFunction<T>>
    ) => css`
      @media (max-width: ${breakpoints[breakpoint as BreakpointSize]}) {
        ${args};
      }
    `;
  });
  return mediaquery;
};

export const media = getMediaBreakpoints();
