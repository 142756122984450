import axios, { AxiosError, AxiosPromise } from 'axios';
import i18n from 'i18next';
import toast from 'react-hot-toast';
import {
  getAccessToken,
  isAuthenticated,
  updateToken
} from 'src/components/pages/Auth/keycloak.config';

export type ApiError = {
  timestamp?: string;
  status?: number;
  error?: string;
  message?: string;
  path?: string;
};

type Response<T> = {
  ok: boolean;
  data: T;
  error?: ApiError;
};

const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === 'object') {
    return 'response' in (error as object);
  }

  return false;
};

axios.defaults.baseURL = process.env.REACT_APP_API + '/api';

axios.interceptors.request.use(
  async config => {
    if (isAuthenticated()) {
      const setAuthorizationHeader = () => {
        if (config.headers) {
          config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        }
        return config;
      };
      return await updateToken(setAuthorizationHeader);
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    const status = error?.response?.status;

    if (status === 401 || status === 403) {
      window.location.href = '/unauthorized';
      toast.error(i18n.t('error.unauthorizedMessage'));
    }

    if (status === 404) {
      toast.error(i18n.t('error.notFoundMessage'));
    }

    // if (status === 500) {
    //   toast.error(i18n.t('error.serverError'));
    // }
    throw error;
  }
);

async function tryAjax<T>(func: () => AxiosPromise): Promise<Response<T>> {
  try {
    const { data } = await func();
    return {
      ok: true,
      data: data as T
    };
  } catch (error: unknown) {
    return {
      ok: false,
      data: {} as unknown as T,
      error: (isAxiosError(error) ? error.response?.data : {}) as ApiError
    };
  }
}

const acceptJsonHeader = {
  Accept: 'application/json'
};

const contentTypeJsonHeader = {
  'Content-Type': 'application/json'
};

export const getRequest = <T = unknown>(url: string) =>
  tryAjax<T>(() => {
    return axios({
      method: 'GET',
      url: url,
      headers: {
        ...acceptJsonHeader
      }
    });
  });

export const postRequest = <T = unknown>(
  url: string,
  data?: unknown,
  excludeContentType?: boolean
) => {
  const headers = excludeContentType
    ? {
        ...acceptJsonHeader
      }
    : {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader
      };

  return tryAjax<T>(() =>
    axios({
      method: 'POST',
      url: url,
      headers: headers,
      data: data
    })
  );
};

export const putRequest = <T = unknown>(url: string, data?: unknown) =>
  tryAjax<T>(() =>
    axios({
      method: 'PUT',
      url: url,
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader
      },
      data: data
    })
  );

export const deleteRequest = <T = unknown>(url: string, data?: unknown) =>
  tryAjax<T>(() =>
    axios({
      method: 'DELETE',
      url: url,
      headers: {
        ...acceptJsonHeader
      },
      data: data
    })
  );

export const fetcher = (url: string) =>
  axios.get(url, { headers: { ...acceptJsonHeader } }).then(res => {
    return res.data;
  });
