type IconProps = {
  className?: string;
  size?: number;
  type: string;
  onClick?: () => void;
};

export const Icon = ({ type, size = 24, className, onClick }: IconProps) => {
  return (
    <i
      className={`las ${type} ${className ? className : ''}`}
      style={{ fontSize: size }}
      onClick={onClick}
    />
  );
};
